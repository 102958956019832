import httpPilotar from '@/plugins/httpPilotar';
import { Get } from '@/services/BaseService';
import ServiceDTO from '../DTOs/services/ServiceDTO';

export default class ServicesService {

    public static getToSchedulingPracticalLessons(): Promise<ServiceDTO[]>{
        return Get<ServiceDTO[]>(`/academic/services/get-to-scheduling-practical-lessons`, {}, { customClient: httpPilotar });
    }

}