import httpPilotar from '@/plugins/httpPilotar';
import { Get } from '@/services/BaseService';
import CompanyDTO from '../DTOs/companies/CompanyDTO';

export default class CompaniesService {

    public static readonly baseUrl = process.env.VUE_APP_API_FIREBASE_URL;

    public static getByIdentityDocument(name: string, value: string): Promise<CompanyDTO>{
        return Get<CompanyDTO>(`${this.baseUrl}/core/companies/get-by-identity-document`, { name: name, value: value }, { customClient: httpPilotar });
    }
}
