
import { VsIcon } from '@/components/controls/vs-icon';
import { Component, Vue } from 'vue-property-decorator';
import NotificationIcon from '../../components/notificationIcon/NotificationIcon.vue';
import NotificationCard from './NotificationCard.vue';

@Component({ components: { VsIcon, NotificationCard, NotificationIcon } })
export default class NotificationsListPage extends Vue {

    public get notifications() {
        return this.$store.getters['getNotifications'];
    }   

    public close() {
        this.$store.commit('goBack');
    }
}
