import httpPilotar from '@/plugins/httpPilotar';
import { Get } from '@/services/BaseService';
import UserDTO from '../DTOs/UserDTO';

export default class UsersService {

    public static BaseUrl = process.env.VUE_APP_API_FIREBASE_URL;

    public static getById(id: string): Promise<UserDTO>{
        return Get<UserDTO>(`${this.BaseUrl}/auth/users/get-by-id`, { id: id }, { customClient: httpPilotar });
    }
}