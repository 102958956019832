
import { VsIcon } from '@/components/controls/vs-icon';
import { Component, Vue } from 'vue-property-decorator';

@Component({ components: { VsIcon } })
class NotificationIcon extends Vue {

    public get notificationsLength(): string {
        return this.$store.getters['getNotifications'].length.toString();
    }
    
}

export default NotificationIcon;
