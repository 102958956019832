import httpPilotar from '@/plugins/httpPilotar';
import { Get } from '@/services/BaseService';
import ResourceDTO from '@/modules/scheduling/DTOs/resources/ResourceDTO';
import TeacherDTO from '../DTOs/teachers/TeacherDTO';

export default class TeachersService {

    public static getByIdentifier(identifier: string): Promise<ResourceDTO[]>{
        return Get<ResourceDTO[]>(`/academic/resources/get-by-identifier`, { "identifier": identifier }, { customClient: httpPilotar });
    }
  
    public static getAvailableForScheduling(date: string, serviceId: string): Promise<TeacherDTO[]>{
        return Get<TeacherDTO[]>(`/academic/teachers/get-available-for-scheduling`, { "date": date, "serviceId": serviceId }, { customClient: httpPilotar });
    }
  
}