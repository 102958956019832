import Vue from 'vue';
import App from './App.vue';
import router from './router';

import {
    // Controles
    VsCheckbox,
    VsCircleAvatar,
    VsDropdown,
    VsFlatButton,
    VsIcon,
    VsInput,
    VsLoading,
    VsBlockingLoading,
    VsSearching,
    VsRadio,
    VsRating,
    VsRating2,
    VsSelect,
    VsSelectWithButton,
    VsSlider,
    VsSwitch,
    VsTimer,
    VsToolbar,
    VsToolbarItem,

    // Layouts
    VsAlert,
    VsFlex,
    VsRow,
    VsColumn,
    VsModal,
    VsSelectModal,
    VsToast,

    // Pages
    VsApp,
    VsPage,
} from './components';

Vue.component('VsCheckbox', VsCheckbox);
Vue.component('VsCircleAvatar', VsCircleAvatar);
Vue.component('VsDropdown', VsDropdown);
Vue.component('VsFlatButton', VsFlatButton);
Vue.component('VsIcon', VsIcon);
Vue.component('VsInput', VsInput);
Vue.component('VsLoading', VsLoading);
Vue.component('VsBlockingLoading', VsBlockingLoading);
Vue.component('VsSearching', VsSearching);
Vue.component('VsRadio', VsRadio);
Vue.component('VsRating', VsRating);
Vue.component('VsRating2', VsRating2);
Vue.component('VsSelect', VsSelect);
Vue.component('VsSelectWithButton', VsSelectWithButton);
Vue.component('VsSlider', VsSlider);
Vue.component('VsSwitch', VsSwitch);
Vue.component('VsTimer', VsTimer);
Vue.component('VsToolbar', VsToolbar);
Vue.component('VsToolbarItem', VsToolbarItem);

Vue.component('VsAlert', VsAlert);
Vue.component('VsFlex', VsFlex);
Vue.component('VsRow', VsRow);
Vue.component('VsColumn', VsColumn);
Vue.component('VsModal', VsModal);
Vue.component('VsSelectModal', VsSelectModal);
Vue.component('VsToast', VsToast);

Vue.component('VsApp', VsApp);
Vue.component('VsPage', VsPage);

import Hotjar from 'vue-hotjar';
Vue.use (Hotjar, {
    id: process.env.VUE_APP_HOTJAR_ID,
    isProduction: true
});

import PilotarAlert from '@/plugins/pilotarAlert';
Vue.use(PilotarAlert);

import Vue2TouchEvents from 'vue2-touch-events';
Vue.use(Vue2TouchEvents);

import VueZoomer from 'vue-zoomer';
Vue.use(VueZoomer);

import VCalendar from 'v-calendar';
Vue.use(VCalendar);

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import { faArrowsRotate as fasArrowsRotate } from '@fortawesome/free-solid-svg-icons';

/* add icons to the library */
library.add(fasArrowsRotate);

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

import VWave from 'v-wave';
Vue.use(VWave, {
    color: 'black)',
    easing: 'ease-in',
    duration: 0.3,
    directive: 'ripple'
});

import moment from 'moment';
moment.locale('pt-br');

// ========== Vee validate ==========
import { extend, localize, ValidationProvider } from 'vee-validate';
import { required, email, min, confirmed, max, alpha_spaces, digits, is, is_not } from 'vee-validate/dist/rules';
import ptBR from './utils/validate-pt_BR.json';
import noEmoji from '@/utils/noEmoji';
Vue.component('ValidationProvider', ValidationProvider);

// rules.
extend('is_not', is_not);
extend('is', is);
extend('digits', digits);
extend('alpha_spaces', alpha_spaces);
extend('required', required);
extend('email', email);
extend('min', min);
extend('confirmed', confirmed);
extend('max', max);
extend('min', min);
extend('cpfemail', {
    params: [],
    validate: (value) => {
        const email = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;
        const cpf = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
        return email.test(value) || cpf.test(value);
    },
    message: "Por favor, insira um e-mail ou CPF v�lido."
});

extend('cpf', {
    params: [],
    validate: (value) => {

        const validCpf = (cpf: string) => {
            const noMaskCpf = cpf.replace(new RegExp('[.-]','gi'), '');
            let Soma;
            let Resto;
            Soma = 0;
            if (noMaskCpf == "00000000000") return false;
            if (noMaskCpf == "11111111111") return false;
            if (noMaskCpf == "22222222222") return false;
            if (noMaskCpf == "33333333333") return false;
            if (noMaskCpf == "44444444444") return false;
            if (noMaskCpf == "55555555555") return false;
            if (noMaskCpf == "66666666666") return false;
            if (noMaskCpf == "77777777777") return false;
            if (noMaskCpf == "88888888888") return false;
            if (noMaskCpf == "99999999999") return false;
             
            for (let i=1; i<=9; i++) Soma = Soma + parseInt(noMaskCpf.substring(i-1, i)) * (11 - i);
            Resto = (Soma * 10) % 11;
           
            if ((Resto == 10) || (Resto == 11))  Resto = 0;
            if (Resto != parseInt(noMaskCpf.substring(9, 10)) ) return false;
           
            Soma = 0;
            for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(noMaskCpf.substring(i-1, i)) * (12 - i);
            Resto = (Soma * 10) % 11;
           
            if ((Resto == 10) || (Resto == 11))  Resto = 0;
            if (Resto != parseInt(noMaskCpf.substring(10, 11) ) ) return false;
            return true;
        };

        const cpf = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
        return cpf.test(value) && validCpf(value);
    },
    message: "Por favor, insira um CPF v�lido."
});
extend('phone', {
    params: [],
    validate: (value) => {
        const phone = /^\([1-9]{2}\) [0-9]{5}-[0-9]{4}/i;
        return phone.test(value);
    },
    message: "Por favor, insira um e-mail ou cpf v�lido."
});
extend('senha', {
    params: [],
    validate: (value) => {
        const hasNumber = /[0-9]/.test(value);
        const hasLetter = /[A-Za-z]/.test(value);
        const hasEightCharacters = value.length >= 8;
        return hasNumber && hasLetter && hasEightCharacters;
    },
    message: "A senha precisa ter pelo menos um n�mero e oito caracteres."
});
extend('emoji', noEmoji);
// Install English and Arabic localizations.
localize({
    ptBR: {
        messages: ptBR.messages
    }
});
localize('ptBR');

Vue.config.productionTip = false;

import store from './store';
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');