import httpPilotar from '@/plugins/httpPilotar';
import { Get, Post } from '@/services/BaseService';
import NotificationDTO from '../DTOs/notifications/NotificationDTO';

export default class NotificationsService {

    public static BaseUrl = process.env.VUE_APP_API_FIREBASE_URL;

    public static getUnreadBySession(): Promise<NotificationDTO[]>{
        return Get<NotificationDTO[]>(`${this.BaseUrl}/core/notifications/get-unread-by-session`, { }, { customClient: httpPilotar });
    }

    public static read(notifications: NotificationDTO[]): Promise<void>{
        return Post(`${this.BaseUrl}/core/notifications/read`, { id: notifications.map(notification => notification.id) }, httpPilotar);
    }
}