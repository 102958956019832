import httpPilotar from '@/plugins/httpPilotar';
import { Get } from '@/services/BaseService';
import StudentDTO from '../DTOs/students/StudentDTO';

export default class TeachersService {

    public static getByUserId(userId: string): Promise<StudentDTO>{
        return Get<StudentDTO>(`/academic/students/get-by-user-id`, { "userId": userId }, { customClient: httpPilotar });
    }
  
    public static getByIdentityDocument(value: string): Promise<StudentDTO>{
        return Get<StudentDTO>(`/academic/students/get-by-identity-document`, { "name": "CNPJ", "value": value }, { customClient: httpPilotar });
    }
  
}