
import UserService from '@/modules/auth/services/UsersService';
import ImageViewer from '@/modules/storage/components/ImageViewer.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ components: { ImageViewer } })
export default class UserAvatar extends Vue {

    @Prop({ type: String })
    public userId!: string;

    @Prop({ type: String })
    public userName!: string;

    @Prop({ type: String, default: '40px' })
    public size!: string;

    @Prop({ type: String, default: '22px' })
    public initialsFontSize!: string;

    @Prop({ type: String, default: '10px' })
    public initialsPaddingTop!: string;

    public loading: boolean = true;
    public hasAvatar: boolean = false;

    public get initials() {
        const arr = this.userName.split(' ');
        let initials = '';
        if (arr && arr.length > 0) {
            if (arr.length > 1) {
                initials = `${arr[0].substring(0, 1)}${arr[arr.length - 1].substring(0, 1)}`;
            }
            else {
                initials = `${arr[0].substring(0, 1)}`;
            }
        }
        return initials;
    }

    private async mounted() {
        this.loading = true; 
        this.hasAvatar = (await UserService.getById(this.userId))?.hasAvatar ?? false;
        this.loading = false; 
    }
}
