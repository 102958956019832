
import { Component, Vue } from 'vue-property-decorator';

@Component({ })
class AddCreditsPage extends Vue {
    public back() {


        this.$store.commit('goBack');
        /*
        this.$store.commit('clearStack', {
            page: "new-scheduling-category", transition: "toUpReverse"
        });
        */
    }
}

export default AddCreditsPage;
