
import { Component, Vue, Prop } from 'vue-property-decorator';
import { VsIcon } from '@/components/controls/vs-icon';
import Lottie from "@/components/Lottie.vue";


@Component({ components: { VsIcon, Lottie } })
class NotFoundMessage extends Vue {

    @Prop({ type: String, default: "Dados n�o encontrados" })
    public title!: string;

    @Prop({ type: String, default: "Verifique os filtros e tente uma nova pesquisa" })
    public text!: string;

}

export default NotFoundMessage;
