
import { VsIcon } from '@/components/controls/vs-icon';
import UserAvatar from '@/modules/auth/components/userAvatar/UserAvatar.vue';
import { formatDate } from '@/utils/stringUtils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import NotificationDTO from '../../DTOs/notifications/NotificationDTO';

@Component({ components: { VsIcon, UserAvatar } })
export default class NotificationCard extends Vue {

    @Prop({ type: Object, default: 50 })
    public notification!: NotificationDTO;

    public updating: boolean = false;

    public get formattedDate() {
        return formatDate;
    }

    public async read() {
        this.updating = true;
        await this.$store.dispatch('readNotifications', [this.notification]);
        this.$emit('read');
        this.updating = false;
    }

}
